import { addPropertyControls, ControlType } from "framer"
import { motion } from "framer-motion"
import { cloneElement } from "react"

function getTweetIdFromUrl(url) {
    const regex = /\/status\/(\d+)/
    const match = url.match(regex)

    if (match && match[1]) {
        return match[1]
    } else {
        // Return null or handle the case when the URL doesn't match the expected format.
        return null
    }
}

/**
 * @framerSupportedLayoutWidth any
 * @framerSupportedLayoutHeight any
 */
export default function XActionButton(props) {
    const { border } = props

    let url = "https://" + props.domain + "/intent/"

    const text = encodeURIComponent(props.content)

    let link = ""
    switch (props.linkType) {
        case "urlString":
            link = props.linkString
            break
        case "link":
            link = props.linkLink
            break
        case "currentPageUrl":
            if (typeof window != "undefined") {
                link = window.location.href.replace(/^https?:\/\//, "")
            }
            break
    }

    let linkSegment = ""
    if (link.length > 0) {
        linkSegment = `&url=${link}`
    }

    switch (props.action) {
        case "follow":
            url += `follow?screen_name=${props.username.replace(/^@/, "")}`
            break
        case "post":
            url += `tweet?text=${text}${linkSegment}`
            break
        case "reply":
            url += `tweet?in_reply_to=${getTweetIdFromUrl(
                props.postUrl
            )}&text=${text}${linkSegment}`
            break
        case "retweet":
            url += `retweet?tweet_id=${getTweetIdFromUrl(props.postUrl)}`
            break
        case "likePost":
            url += `like?tweet_id=${getTweetIdFromUrl(props.postUrl)}`
            break
    }

    const borderRadius = props.radiusIsMixed
        ? `${props.topLeft}px ${props.topRight}px ${props.bottomRight}px ${props.bottomLeft}px`
        : `${props.radius}px`

    let button
    if (props.buttonType == "default") {
        button = (
            <a
                href={url}
                style={{
                    position: "relative",
                    display: "flex",
                    flexDirection: "row",
                    gap: props.gap,
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: props.fill,
                    boxSizing: "border-box",
                    textDecoration: "none",
                    color: props.fontColor,
                    ...props.font,
                    padding: props.paddingIsMixed
                        ? `${props.paddingTop}px ${props.paddingRight}px ${props.paddingBottom}px ${props.paddingLeft}px`
                        : `${props.padding}px`,
                    borderRadius: borderRadius,
                    ...props.style,
                }}
            >
                {props.text}
                {props.logo && (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={props.logo.size}
                        height={props.logo.size}
                        viewBox="0 0 24 24"
                    >
                        <path
                            d="M 18.244 2.25 L 21.552 2.25 L 14.325 10.51 L 22.827 21.75 L 16.17 21.75 L 10.956 14.933 L 4.99 21.75 L 1.68 21.75 L 9.41 12.915 L 1.254 2.25 L 8.08 2.25 L 12.793 8.481 Z M 17.083 19.77 L 18.916 19.77 L 7.084 4.126 L 5.117 4.126 Z"
                            fill={props.logo.color}
                        ></path>
                    </svg>
                )}
                {border && (
                    <div
                        style={{
                            position: "absolute",
                            inset: 0,
                            borderRadius: borderRadius,
                            borderWidth: border.widthIsMixed
                                ? `${border.widthTop}px ${border.widthRight}px ${border.widthBottom}px ${border.widthLeft}px`
                                : `${border.width}px`,
                            borderStyle: border.style,
                            borderColor: border.color,
                        }}
                    />
                )}
            </a>
        )
    } else {
        let layer = props.custom?.[0]
        if (layer && (props.style?.width || props.style?.height)) {
            layer = cloneElement(layer, {
                style: {
                    ...layer.props.style,
                    ...props.style,
                },
            })
        }
        button = (
            <a href={url} style={props.style}>
                {layer}
            </a>
        )
    }

    let layer = button
    if (props.effects) {
        layer = (
            <motion.div
                whileHover={{ scale: props.effects.hoverScale }}
                whileTap={{ scale: props.effects.pressScale }}
                initial={false}
                transition={props.effects.transition}
                style={props.style}
            >
                {button}
            </motion.div>
        )
    }

    return layer
}

XActionButton.displayName = "X Share Button"

const logoDefault = {
    color: "#FFF",
    size: 24,
}

const effectsDefault = {
    hoverScale: 1.05,
    pressScale: 0.95,
}

addPropertyControls(XActionButton, {
    action: {
        type: ControlType.Enum,
        defaultValue: "follow",
        options: ["follow", "post", "reply", "retweet", "likePost"],
        optionTitles: ["Follow", "Post", "Reply", "Retweet", "Like Post"],
    },
    postUrl: {
        type: ControlType.String,
        placeholder:
            "https://twitter.com/framestack_/status/1706327085940126004",
        title: "Post URL",
        hidden: (props) =>
            !["reply", "retweet", "likePost"].includes(props.action),
    },
    content: {
        type: ControlType.String,
        displayTextArea: true,
        hidden: (props) => !["post", "reply"].includes(props.action),
    },
    linkType: {
        type: ControlType.Enum,
        defaultValue: "none",
        options: ["none", "urlString", "link", "currentPageUrl"],
        optionTitles: ["None", "URL String", "Link", "Current Page URL"],
        title: "Link",
        description: "Adds a link to the end of the post.",
        hidden: (props) => !["post", "reply"].includes(props.action),
    },
    linkString: {
        type: ControlType.String,
        placeholder: "framestack.co",
        title: " ",
        hidden: (props) =>
            !["post", "reply"].includes(props.action) ||
            props.linkType != "urlString",
    },
    linkLink: {
        type: ControlType.Link,
        title: " ",
        hidden: (props) =>
            !["post", "reply"].includes(props.action) ||
            props.linkType != "link",
    },
    username: {
        type: ControlType.String,
        placeholder: "@framestack_",
        description: "@ symbol not required.",
        hidden: (props) => props.action != "follow",
    },
    domain: {
        type: ControlType.Enum,
        defaultValue: "x.com",
        options: ["x.com", "twitter.com"],
    },
    newTab: {
        type: ControlType.Boolean,
        defaultValue: true,
    },
    buttonType: {
        type: ControlType.Enum,
        defaultValue: "default",
        options: ["default", "custom"],
        optionTitles: ["Default", "Custom"],
        displaySegmentedControl: true,
        title: "Button",
    },
    custom: {
        type: ControlType.ComponentInstance,
        title: "Custom",
        description: "Use a custom button layer.",
        hidden(props) {
            return props.buttonType !== "custom"
        },
    },
    fill: {
        type: ControlType.Color,
        defaultValue: "#000",
        optional: true,
        hidden: (props) => props.buttonType != "default",
    },
    fontColor: {
        type: ControlType.Color,
        defaultValue: "#FFF",
        hidden: (props) => props.buttonType != "default",
    },
    font: {
        type: "font",
        controls: "extended",
        defaultFontType: "sans-serif",
        defaultValue: {
            fontSize: 14,
            lineHeight: 1.4,
        },
        hidden: (props) => props.buttonType != "default",
    },
    text: {
        type: ControlType.String,
        defaultValue: "Follow on",
        hidden: (props) => props.buttonType != "default",
    },
    logo: {
        type: ControlType.Object,
        defaultValue: logoDefault,
        optional: true,
        controls: {
            color: {
                type: ControlType.Color,
                defaultValue: logoDefault.color,
            },
            size: {
                type: ControlType.Number,
                defaultValue: logoDefault.size,
                min: 1,
            },
        },
        hidden: (props) => props.buttonType != "default",
    },
    border: {
        type: ControlType.Object,
        optional: true,
        controls: {
            color: {
                type: ControlType.Color,
                defaultValue: "#222",
            },
            width: {
                type: ControlType.FusedNumber,
                defaultValue: 1,
                toggleKey: "widthIsMixed",
                toggleTitles: ["All", "Individual"],
                valueKeys: [
                    "widthTop",
                    "widthRight",
                    "widthBottom",
                    "widthLeft",
                ],
                valueLabels: ["T", "R", "B", "L"],
                min: 0,
            },
            style: {
                type: ControlType.Enum,
                defaultValue: "solid",
                options: ["solid", "dashed", "dotted", "double"],
                optionTitles: ["Solid", "Dashed", "Dotted", "Double"],
            },
        },
        hidden: (props) => props.buttonType != "default",
    },
    gap: {
        type: ControlType.Number,
        defaultValue: 8,
        min: 0,
        step: 1,
        displayStepper: true,
        hidden: (props) => props.buttonType != "default",
    },
    padding: {
        type: ControlType.FusedNumber,
        defaultValue: 14,
        toggleKey: "paddingIsMixed",
        toggleTitles: ["All", "Individual"],
        valueKeys: [
            "paddingTop",
            "paddingRight",
            "paddingBottom",
            "paddingLeft",
        ],
        valueLabels: ["T", "R", "B", "L"],
        min: 0,
        hidden: (props) => props.buttonType != "default",
    },
    radius: {
        type: ControlType.FusedNumber,
        defaultValue: 12,
        toggleKey: "radiusIsMixed",
        toggleTitles: ["All", "Individual"],
        valueKeys: ["topLeft", "topRight", "bottomRight", "bottomLeft"],
        valueLabels: ["TL", "TR", "BR", "BL"],
        min: 0,
        hidden: (props) => props.buttonType != "default",
    },
    effects: {
        type: ControlType.Object,
        defaultValue: effectsDefault,
        optional: true,
        buttonTitle: "Hover & Press",
        icon: "effect",
        controls: {
            hoverScale: {
                type: ControlType.Number,
                defaultValue: effectsDefault.hoverScale,
                min: 0,
                step: 0.01,
                displayStepper: true,
            },
            pressScale: {
                type: ControlType.Number,
                defaultValue: effectsDefault.pressScale,
                min: 0,
                step: 0.01,
                displayStepper: true,
            },
            transition: {
                type: ControlType.Transition,
            },
        },
    },
})
